<script>
import VueRecaptcha from 'vue-recaptcha'

import RegistrationApiService from '../../../services/domains/Registration'
import StageApiService from '../../../services/domains/Stage'
import CityApiService from '../../../services/domains/City'
import EmptyList from '../../../components/general/EmptyList'
import TransactionPaymentModal from "../../../components/general/TransactionPaymentModal.vue";

import DataService from '../../../services/common/data'
import * as notifyService from '../../../services/common/notify'

import bus from '@utils/bus'
import helpers from '@mixins/helper'
import {mapActions, mapGetters} from 'vuex'
import * as CPF from '../../../../utils/cpf'

import {required, requiredIf, email} from 'vuelidate/lib/validators'
import {clone, debounce} from 'lodash'

const validCPF = (cpf) => CPF.validate(cpf)
const isProduction = process.env.NODE_ENV === 'production'
const registrationService = RegistrationApiService.build({})
const stageService = StageApiService.build({})
const cityService = CityApiService.build({})

export default {
  components: {TransactionPaymentModal, VueRecaptcha, EmptyList},
  mixins: [helpers],
  data() {
    return {
      isLoading: true,
      isSubmitted: false,
      isFixedStage: false,
      isRegistrationFinished: false,
      showPixModal: false,
      isRecaptchaValid: false,
      isSearchingCities: false,
      isLoadingAmount: true,
      showConfirmDialog: false,
      registrationTries: 0,
      searchCity: '',
      player1phonePrefix: '+55',
      player2phonePrefix: '+55',
      player1SearchCity: '',
      player2SearchCity: '',
      stage: {
        id: '',
        players_class: []
      },
      form: {
        stage_id: '',
        settings: {
          payment_unique: false,
        },
        tenant_id: '',
        registration_without_partner: false,
        player_class_id: '',
        player1_client_id: '',
        player1_country_code: 'BRA',
        player1_first_name: '',
        player1_last_name: '',
        player1_email: '',
        player1_phone: '',
        player1_cpf: '',
        player1_city_id: '',
        player1_amount: 0,
        player1_full_amount: 0,
        player1_discount: 0,
        player1_data: {
          shirt_type: null,
          shirt_size: null,
        },
        player2_client_id: '',
        player2_country_code: 'BRA',
        player2_first_name: '',
        player2_last_name: '',
        player2_email: '',
        player2_phone: '',
        player2_cpf: '',
        player2_city_id: '',
        player2_amount: 0,
        player2_full_amount: 0,
        player2_discount: 0,
        player2_data: {
          shirt_type: null,
          shirt_size: null,
        },
        impediments: [],
        accept_terms: false,
        coupon: '',
      },
      couponModel: '',
      payment: {
        transaction: null,
        showModal: false,
        expired: false,
      },
      dependencies: {
        stages: [],
        players_class: [],
        player1_cities: [],
        player2_cities: [],
        countries: [],
        sex: [],
        shirt_type: [],
        shirt_size: []
      }
    }
  },
  validations: {
    form: {
      stage_id: {required},
      player_class_id: {required},
      player1_country_code: {required},
      player1_first_name: {required},
      player1_last_name: {required},
      player1_email: {required, email},
      player1_phone: {required},
      player1_cpf: {
        required: requiredIf(function () {
          return this.registrationCpfRequired
        })
      },
      player1_city_id: {
        required: requiredIf(function () {
          return this.registrationCityRequired
        })
      },
      player1_sex: {
        required: requiredIf(function () {
          return this.registrationSexRequired
        })
      },
      player1_data: {
        shirt_type: {
          required: requiredIf(function () {
            return this.registrationShirtRequired
          })
        },
        shirt_size: {
          required: requiredIf(function () {
            return this.registrationShirtRequired
          })
        }
      },
      player2_country_code: {
        required: requiredIf(function () {
          return !this.showFormForSinglePlayer
        })
      },
      player2_first_name: {
        required: requiredIf(function () {
          return !this.showFormForSinglePlayer
        })
      },
      player2_last_name: {
        required: requiredIf(function () {
          return !this.showFormForSinglePlayer
        })
      },
      player2_email: {
        required: requiredIf(function () {
          return !this.showFormForSinglePlayer
        }), email
      },
      player2_phone: {
        required: requiredIf(function () {
          return !this.showFormForSinglePlayer
        })
      },
      player2_cpf: {
        required: requiredIf(function () {
          return this.registrationCpfRequired && !this.showFormForSinglePlayer
        })
      },
      player2_city_id: {
        required: requiredIf(function () {
          return this.registrationCityRequired && !this.showFormForSinglePlayer
        })
      },
      player2_sex: {
        required: requiredIf(function () {
          return this.registrationSexRequired && !this.showFormForSinglePlayer
        })
      },
      player2_data: {
        shirt_type: {
          required: requiredIf(function () {
            return this.registrationShirtRequired && !this.showFormForSinglePlayer
          })
        },
        shirt_size: {
          required: requiredIf(function () {
            return this.registrationShirtRequired && !this.showFormForSinglePlayer
          })
        }
      },
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'currentUser']),
    isProduction() {
      return isProduction
    },
    recaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA_KEY
    },
    stageLoaded() {
      return !!this.stage.id && !this.isLoading
    },
    selectedPlayerClass() {
      if (this.stageLoaded) {
        return this.stage.players_class.find(item => item.id === this.form.player_class_id)
      }
      return null
    },
    stageSingle() {
      if (this.stageLoaded) {
        return this.stage.single
      }
      return false
    },
    stageMasterSponsors() {
      if (this.stageLoaded) {
        return this.stage.images.filter(item => item.json && item.json.main_sponsor)
      }
      return []
    },
    stageSponsors() {
      if (this.stageLoaded) {
        return this.stage.images.filter(item => !item.json || (item.json && !item.json.main_sponsor))
      }
      return []
    },
    firstDate() {
      return this.dateToShortDayMonth(this.stage.dates[0], true)
    },
    lastDate() {
      return this.dateToShortDayMonth(this.stage.dates[this.stage.dates.length - 1], true)
    },
    registrationCpfRequired() {
      return this.stageLoaded && this.stage.settings?.registration_required_fields.some(item => item.key === 'cpf')
    },
    registrationCityRequired() {
      return this.stageLoaded && this.stage.settings?.registration_required_fields.some(item => item.key === 'city')
    },
    registrationSexRequired() {
      return this.stageLoaded && this.stage.settings?.registration_required_fields.some(item => item.key === 'sex')
    },
    registrationShirtRequired() {
      return this.stageLoaded && this.stage.settings?.registration_required_fields.some(item => item.key === 'shirt')
    },
    player1UniqueIdentityLabel() {
      const country = this.dependencies.countries.find(item => item.code === this.form.player1_country_code)
      if (country) {
        switch (country.code) {
          case 'URY':
            return this.$t('app.domains.registration.components.form.form.ciLabel')

          case 'ARG':
          case 'PRY':
            return this.$t('app.domains.registration.components.form.form.dniLabel')
        }
      }
      return this.$t('app.domains.registration.components.form.form.cpfLabel')
    },
    player1PhoneMask() {
      const country = this.dependencies.countries.find(item => item.code === this.form.player1_country_code)
      return this.countryPhoneMask(country?.code)
    },
    player2UniqueIdentityLabel() {
      const country = this.dependencies.countries.find(item => item.code === this.form.player2_country_code)
      if (country) {
        switch (country.code) {
          case 'URY':
            return this.$t('app.domains.registration.components.form.form.ciLabel')

          case 'ARG':
          case 'PRY':
            return this.$t('app.domains.registration.components.form.form.dniLabel')
        }
      }
      return this.$t('app.domains.registration.components.form.form.cpfLabel')
    },
    player2PhoneMask() {
      const country = this.dependencies.countries.find(item => item.code === this.form.player2_country_code)
      return this.countryPhoneMask(country?.code)
    },
    cpfUniqueLabel() {
      return this.$t('app.domains.registration.components.form.form.cpfEqualMessage')
    },
    acceptTermsRequiredAndInvalid() {
      return this.stageLoaded
          && this.stage.settings?.accept_terms
          && this.stage.settings?.accept_terms.enabled
          && this.form.accept_terms === false
    },
    paymentEnabled() {
      return this.stageLoaded && (this.stage.settings?.registration_with_payment || false)
    },
    shirtConfig() {
      return this.stageLoaded
          && this.stage.settings?.registration_required_fields
          && (this.stage.settings?.registration_required_fields.find(item => item.key === 'shirt')?.config ?? null)
    },
    dependenciesShirtTypeFiltered() {
      if (!this.shirtConfig) {
        return this.dependencies.shirt_type
      }
      return this.dependencies.shirt_type.filter(item => this.shirtConfig[item.id] && this.shirtConfig[item.id].sizes.length)
    },
    selectedImpediments() {
      return this.stage.impediments.filter(item => this.form.impediments.includes(item.impediment_id)).map(item => item.name).join(', ')
    },
    showFormForSinglePlayer() {
      return this.stageSingle || this.form.registration_without_partner
    },
    hasCouponEnabled() {
      return this.stageLoaded && (this.stage.settings?.coupon_discount || false)
    },
  },
  watch: {
    'form.player2_cpf': function (value) {
      if (value.length === 11 && validCPF(this.form.player2_cpf)) {
        if (this.form.player1_cpf === this.form.player2_cpf) {
          notifyService.warning({message: this.cpfUniqueLabel})
        }
      }
    },
    player1SearchCity: debounce(function (value) {
      value && value !== this.form.player1_city_id && this.onSearchCities(value, 'player1')
    }, 300),
    player2SearchCity: debounce(function (value) {
      value && value !== this.form.player2_city_id && this.onSearchCities(value, 'player2')
    }, 300),
    'form.player1_data.shirt_type': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.player1_data.shirt_size = ''
      }
    },
    'form.player2_data.shirt_type': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form.player2_data.shirt_size = ''
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
    bus.$off('qrcode-timer-expired')
  },
  async mounted() {
    window.addEventListener('resize', this.resize)
    this.getDependencies()
    if (this.$route.params.slug !== undefined) {
      const [, tenantStage] = this.$route.params.slug.split('--')
      const [tenantId, stageId] = tenantStage.split('-')
      if (tenantId && stageId) {
        await this.getStage(tenantId, stageId)
      }
      this.isLoading = false
    } else {
      this.isLoading = false
    }
    bus.$on('qrcode-timer-expired', () => {
      this.payment.transaction = null
      this.payment.showModal = false
      this.payment.expired = true
    })
    setTimeout(() => {
      if (this.isLogged) {
        const {firstName, lastName} = this.getFirstAndLastName(this.currentUser.name)
        if (this.currentUser.country_code) {
          this.form.player1_country_code = this.currentUser.country_code
        }
        this.form.player1_first_name = firstName
        this.form.player1_last_name = lastName
        this.form.player1_email = this.currentUser.email
        if (this.currentUser.cpf && this.registrationCpfRequired) {
          this.form.player1_cpf = this.currentUser.cpf
        }
        if (this.currentUser.phone) {
          this.form.player1_phone = this.currentUser.phone
        }
        if (this.currentUser.sex) {
          this.form.player1_sex = this.currentUser.sex
        }
        if (this.currentUser.city_id && this.registrationCityRequired) {
          this.form.player1_city_id = this.currentUser.city_id
        }
        this.getPlayersAmount()
      }
      bus.$emit('hide-loader')
    }, 200)
  },
  methods: {
    ...mapActions(['showNotification']),
    resize() {
      setTimeout(() => {
        if (this.$refs.pageContent && this.$refs.stageHeaderContent) {
          if (this.$vuetify.breakpoint.xs && this.$refs.stageHeaderContent) {
            this.$refs.pageContent.style.paddingTop = `${this.$refs.stageHeaderContent.clientHeight + 30}px`
          } else {
            this.$refs.pageContent.style.paddingTop = '0px'
          }
        }
      }, 200)
    },
    t(key) {
      return this.$t(key)
    },
    getDependencies() {
      DataService.get([{domain: 'sex'}, {domain: 'country'}, {domain: 'shirt_type'}, {domain: 'shirt_size'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    verifyRecaptcha(response) {
      this.isRecaptchaValid = !!response
    },
    expiredRecaptcha() {
      this.isRecaptchaValid = false
    },
    getStage(tenantId, stageId) {
      return new Promise(resolve => {
        const data = {tenant_id: tenantId, id: stageId}
        stageService.get(data)
            .then(response => {
              if (!response.registration_opened) {
                notifyService.warning({
                  message: this.$t('app.domains.registration.components.form.registrationsClosedText')
                })
                this.$router.push({name: 'home'})
                return
              }
              this.stage = {...response}
              this.form.stage_id = response.id
              resolve()
              setTimeout(() => {
                this.resize()
              }, 100)
            })
      })
    },
    onSearchCities(query, player) {
      this.dependencies[`${player}_cities`].splice(0)
      if (query) {
        const data = {country_code: this.form[`${player}_country_code`], query: query}
        cityService.searchAutocomplete(data)
            .then((response) => {
              this.dependencies[`${player}_cities`] = [...response]
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    checkImpediment(impediments, impedimentId) {
      const maxImpediments = this.stage.settings?.max_impediments_per_player || 1
      if (impediments.length > maxImpediments) {
        this.form.impediments = [...impediments.filter(item => item !== impedimentId)]
        notifyService.warning({
          message: this.$t('app.domains.registration.components.form.impediments.message', {value: maxImpediments})
        })
      }
    },
    dependenciesShirtSizeFiltered(playerShirtType) {
      if (!this.shirtConfig) {
        return this.dependencies.shirt_size
      }
      return this.dependencies.shirt_size.filter(item => this.shirtConfig[playerShirtType]
          && this.shirtConfig[playerShirtType].sizes.includes(item.id))
    },
    player1ChangeCountry(code) {
      const country = this.dependencies.countries.find(item => item.code === code)
      this.player1phonePrefix = '+' + country.phone_prefix
    },
    player2ChangeCountry(code) {
      const country = this.dependencies.countries.find(item => item.code === code)
      this.player2phonePrefix = '+' + country.phone_prefix
    },
    playerSelectedCountry(number) {
      if (this.form[`player${number}_country_code`]) {
        return this.dependencies.countries.find(item => item.code === this.form[`player${number}_country_code`]).name || ''
      }
      return null
    },
    playerSelectedCity(number) {
      if (this.form[`player${number}_city_id`]) {
        return this.dependencies[`player${number}_cities`].find(item => item.id === this.form[`player${number}_city_id`]).name_with_state || ''
      }
      return null
    },
    playerSelectedSex(number) {
      if (this.form[`player${number}_sex`]) {
        return this.dependencies.sex.find(item => item.id === this.form[`player${number}_sex`]).name || ''
      }
      return null
    },
    playerSelectedShirt(number) {
      if (this.form[`player${number}_data`].shirt_type && this.form[`player${number}_data`].shirt_size) {
        const shirtType = this.dependencies.shirt_type.find(item => item.id === this.form[`player${number}_data`].shirt_type)
        const shirtSize = this.dependencies.shirt_size.find(item => item.id === this.form[`player${number}_data`].shirt_size)
        return `${shirtType.name} ${shirtSize.name}`
      }
      return null
    },
    confirmRegistration() {
      this.handleShowConfirmDialog()
    },
    saveRegistration() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.form)
      data.player1_email = data.player1_email.toLowerCase().trim()
      data.player1_phone = this.onlyNumbers(data.player1_phone.trim())
      if (data.player1_cpf) {
        data.player1_cpf = this.onlyNumbers(data.player1_cpf.trim())
      }
      data.player2_email = data.player2_email.toLowerCase().trim()
      data.player2_phone = this.onlyNumbers(data.player2_phone.trim())
      if (data.player2_cpf) {
        data.player2_cpf = this.onlyNumbers(data.player2_cpf.trim())
      }
      data.stage_id = this.stage.id
      data.tenant_id = this.stage.tenant_id
      registrationService.save({data})
          .then(response => {
            if (response.code !== undefined && response.code === 409) {
              if (this.registrationTries <= 2) {
                this.registrationTries++
                setTimeout(() => {
                  this.saveRegistration()
                }, 500)
              } else {
                bus.$emit('hide-loader')
                const {message} = response
                notifyService.warning({message})
              }
            } else {
              bus.$emit('hide-loader')
              const {title, message} = response
              this.showNotification({
                title,
                message,
                type: 'success',
                timeout: 8000
              })
              this.isSubmitted = false
              this.showConfirmDialog = false
              const {transaction} = response
              if (transaction) {
                this.payment.transaction = {...transaction}
                this.payment.showModal = true
              }
              this.isRegistrationFinished = true
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    async handleShowConfirmDialog() {
      try {
        bus.$emit('show-loader')
        await this.getPlayersAmount()
        setTimeout(() => {
          this.showConfirmDialog = !this.showConfirmDialog
        }, 200)
      } finally {
        bus.$emit('hide-loader')
      }
    },
    async getPlayersAmount() {
      if (!this.form.player_class_id) {
        return
      }
      return new Promise(async (resolve) => {
        this.isLoadingAmount = true
        try {
          const data = clone(this.form)
          data.stage_id = this.stage.id
          data.tenant_id = this.stage.tenant_id
          data.player1_email = data.player1_email.toLowerCase().trim()
          data.player1_phone = this.onlyNumbers(data.player1_phone.trim())
          if (data.player1_cpf) {
            data.player1_cpf = this.onlyNumbers(data.player1_cpf.trim())
          }
          data.player2_email = data.player2_email.toLowerCase().trim()
          data.player2_phone = this.onlyNumbers(data.player2_phone.trim())
          if (data.player2_cpf) {
            data.player2_cpf = this.onlyNumbers(data.player2_cpf.trim())
          }
          const result = await registrationService.getPlayersAmount(data)
          this.form.settings.payment_unique = result.settings?.payment_unique
          this.form.player1_client_id = result.player1_client_id
          this.form.player1_amount = result.player1_amount
          this.form.player1_full_amount = result.player1_full_amount
          this.form.player1_discount = result.player1_discount
          this.form.player2_client_id = result.player2_client_id
          this.form.player2_amount = result.player2_amount
          this.form.player2_full_amount = result.player2_full_amount
          this.form.player2_discount = result.player2_discount
          setTimeout(() => {
            this.isLoadingAmount = false
            resolve()
          }, 200)
        } catch (e) {
          this.isLoadingAmount = false
          console.log(e)
          bus.$emit('hide-loader')
          resolve()
        }
      })
    },
    filterListFilters(item, queryText, itemText) {
      if (itemText && queryText) {
        return itemText.toLowerCase().includes(queryText.toLowerCase())
      }
      return true
    },
    onUpdatePlayerData: debounce(function () {
      this.getPlayersAmount()
    }, 300),
    onApplyCoupon() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        tenant_id: this.stage.tenant_id,
        stage_id: this.stage.id,
        coupon: this.couponModel,
        stage_payment_unique: this.form.settings.payment_unique,
        amounts: {
          player1_full_amount: this.form.player1_full_amount,
          player1_amount: this.form.player1_amount,
          player1_discount: this.form.player1_discount,
          player2_full_amount: this.form.player2_full_amount,
          player2_amount: this.form.player2_amount,
          player2_discount: this.form.player2_discount,
        }
      }
      registrationService.validateCoupon(data)
          .then(response => {
            this.form.coupon_error = null
            const {error} = response
            if (error) {
              this.form.coupon_error = error
            } else {
              this.form.player1_amount = response.amounts.player1_amount
              this.form.player1_discount = response.amounts.player1_discount
              this.form.player2_amount = response.amounts.player2_amount
              this.form.player2_discount = response.amounts.player2_discount
              this.form.coupon = response.coupon
              setTimeout(() => {
                this.couponModel = ''
              }, 100)
            }
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <div>
    <v-card :class="['page-content-card', {'-has-stage-header': $vuetify.breakpoint.xs}]">
      <empty-list v-if="!stageLoaded && !isLoading"
                  title="Torneio não encontrado"/>
      <div class="stage-header"
           v-if="stageLoaded">
        <div class="stage-card-bg" v-if="this.$vuetify.breakpoint.xs"></div>
        <div class="stage-card-content" ref="stageHeaderContent">
          <div class="item -full name">
            <div class="icon">
              <i class="fas fa-trophy"></i>
            </div>
            <div class="text">
              {{ stage.name_with_circuit }}
            </div>
          </div>
          <div class="item date">
            <div class="icon">
              <i class="fas fa-calendar-alt"></i>
            </div>
            <div class="text">
              <span>{{ $t('app.domains.home.components.stageCardDatesFromText') }}</span>
              <strong class="ml-2 mr-2">{{ firstDate }}</strong>
              <span>{{ $t('app.domains.home.components.stageCardDatesToText') }}</span>
              <strong class="ml-2">{{ lastDate }}</strong>
            </div>
          </div>
          <div class="item contact">
            <div class="icon">
              <i class="fas fa-phone"></i>
            </div>
            <div class="text">
              {{ stage.settings?.contact_phones || '' }}
            </div>
          </div>
        </div>
      </div>
      <v-container fluid grid-list-md
                   class="page-content"
                   ref="pageContent">
        <template v-if="stageLoaded && !isRegistrationFinished">
          <v-layout row wrap>
            <v-flex xs12 v-if="stage.settings?.regulation_url" class="mt-3 text-center">
            <span
                class="subheading font-weight-bold">{{
                $t('app.domains.registration.components.form.regulation.title')
              }}:</span>
              <v-btn class="ml-2" small :href="stage.settings.regulation_url" target="_blank" color="primary"
                     :title="$t('app.domains.registration.components.form.regulation.btnTitle')">
                {{ $t('app.domains.registration.components.form.regulation.btnAction') }}
              </v-btn>
            </v-flex>
            <v-flex xs12 class="mt-3" v-if="stage.players_class.length > 0">
              <v-autocomplete
                  :items="stage.players_class"
                  v-model="form.player_class_id"
                  :filter="filterListFilters"
                  :error-messages="validationMessageField($v.form.player_class_id)"
                  @input="$v.form.player_class_id.$touch()"
                  @blur="$v.form.player_class_id.$touch()"
                  @change="onUpdatePlayerData"
                  item-value="id"
                  item-text="name_with_sex"
                  :placeholder="$t('app.texts.selectPlaceholder')"
                  :no-data-text="$t('app.texts.selectEmptyMessage')"
                  clearable>
                <template v-slot:label>
                <span :class="{'red--text': !form.player_class_id}">
                  {{ $t('app.domains.registration.components.form.form.playerClassLabel') }}*
                </span>
                </template>
                <template v-slot:no-data>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="pl-3">{{ $t('app.texts.selectEmptyMessage') }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name_with_sex }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name_with_sex }}
                      <span v-if="item.amount"> - {{ item.amount | currencyEnToBr }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                        class="mt-2"
                        v-if="item.description || (item.registration_start_date && item.registration_end_date)">
                      <template v-if="item.description">
                        {{ item.description }}
                      </template>
                      <template v-if="item.registration_start_date && item.registration_end_date">
                        De: {{ item.registration_start_date | dateTimeEnToBr }}h até
                        {{ item.registration_end_date | dateTimeEnToBr }}h
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-flex>
            <template v-if="stage">
              <v-flex xs12 v-if="paymentEnabled && !!form.player_class_id">
                <v-alert color="amber lighten-5"
                         :value="true"
                         class="rounded mb-2">
                  <div class="black--text text-center">
                    <template v-if="!isLoadingAmount">
                      <template v-if="form.settings.payment_unique">
                        <div>
                          {{ $t('app.domains.registration.components.form.form.paymentUniqueText') }}
                        </div>
                        <div>
                          <strong>{{ (form.player1_amount + form.player2_amount) | currencyEnToBr }}</strong>
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          {{ $t('app.domains.registration.components.form.form.paymentPairText') }}
                        </div>
                        <div>
                          <strong>{{
                              [form.player1_amount, form.player2_amount].sort().reverse()[0] | currencyEnToBr
                            }}</strong>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="font-weight-bold">
                        {{ $t('app.domains.registration.components.form.form.loadingAmountLabel') }}
                      </div>
                    </template>
                  </div>
                </v-alert>
              </v-flex>
              <v-flex :sm12="showFormForSinglePlayer" :sm6="!showFormForSinglePlayer">
                <div class="flex-c">
                  <h4 v-if="!showFormForSinglePlayer">
                    {{ $t('app.domains.registration.components.form.form.playerTitle', {number: 1}) }}</h4>
                  <h4 v-else>{{ $t('app.domains.registration.components.form.form.playerTitleSingle') }}</h4>
                  <v-checkbox
                      v-if="!stageSingle && stage.settings?.registration_without_partner"
                      :label="$t('app.domains.registration.components.form.form.registrationWithoutPartner')"
                      color="primary"
                      v-model="form.registration_without_partner"
                      @change="onUpdatePlayerData"
                      hide-details
                      class="mt-0 pt-0"
                  />
                </div>
                <v-select v-model="form.player1_country_code"
                          :label="$t('app.domains.registration.components.form.form.countryLabel')"
                          :placeholder="$t('app.domains.registration.components.form.form.countryLabel')"
                          :items="dependencies.countries"
                          item-value="code"
                          item-text="name"
                          :error-messages="validationMessageField($v.form.player1_country_code)"
                          @input="$v.form.player1_country_code.$touch()"
                          @blur="$v.form.player1_country_code.$touch()"
                          @change="player1ChangeCountry">
                  <template slot="item" slot-scope="data">
                    <v-list-item-avatar>
                      <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-avatar>
                      <img :src="`/static/flags/${item.flag_icon}`" alt="">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <template v-if="registrationCityRequired">
                  <v-autocomplete v-model="form.player1_city_id"
                                  :search-input.sync="player1SearchCity"
                                  :items="dependencies.player1_cities"
                                  :error-messages="validationMessageField($v.form.player1_city_id)"
                                  :no-data-text="player1SearchCity && player1SearchCity.length > 0 ? $t('app.domains.registration.components.form.form.citiesEmptyList') : $t('app.domains.registration.components.form.form.cityTypeLabel')"
                                  item-value="id"
                                  item-text="name"
                                  clearable
                                  no-filter
                                  :label="$t('app.domains.registration.components.form.form.cityLabel')"
                                  :placeholder="$t('app.domains.registration.components.form.form.cityLabel')">
                    <template v-slot:label>
                      <span :class="{'red--text': !form.player1_city_id}">
                        {{ $t('app.domains.registration.components.form.form.cityLabel') }}*
                      </span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }}
                          <span v-if="data.item.state"> - {{ data.item.state.initials }}</span>
                          <span v-if="data.item.country"> / {{ data.item.country.code }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }}
                          <span v-if="item.state"> - {{ item.state.initials }}</span>
                          <span v-if="item.country"> / {{ item.country.code }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </template>
                <template v-if="registrationCpfRequired">
                  <v-text-field v-model="form.player1_cpf"
                                type="tel"
                                :error-messages="validationMessageField($v.form.player1_cpf)"
                                @input="$v.form.player1_cpf.$touch()"
                                @blur="$v.form.player1_cpf.$touch()"
                                :placeholder="player1UniqueIdentityLabel">
                    <template v-slot:label>
                      <span :class="{'red--text': !form.player1_cpf}">{{ player1UniqueIdentityLabel }}*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-text-field v-model="form.player1_first_name"
                              :error-messages="validationMessageField($v.form.player1_first_name)"
                              @input="$v.form.player1_first_name.$touch()"
                              @blur="$v.form.player1_first_name.$touch()"
                              :label="$t('app.domains.registration.components.form.form.firstNameLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.firstNamePlaceholder')"/>
                <v-text-field v-model="form.player1_last_name"
                              :error-messages="validationMessageField($v.form.player1_last_name)"
                              @input="$v.form.player1_last_name.$touch()"
                              @blur="$v.form.player1_last_name.$touch()"
                              :label="$t('app.domains.registration.components.form.form.lastNameLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.lastNamePlaceholder')"/>
                <v-text-field v-model="form.player1_email"
                              :error-messages="validationMessageField($v.form.player1_email, ['required', 'validEmail'])"
                              @input="$v.form.player1_email.$touch()"
                              @blur="$v.form.player1_email.$touch()"
                              :label="$t('app.domains.registration.components.form.form.emailLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.emailPlaceholder')">
                  <template v-slot:label>
                  <span
                      :class="{'red--text': !form.player1_email}">{{
                      $t('app.domains.registration.components.form.form.emailLabel')
                    }}*</span>
                  </template>
                </v-text-field>
                <v-text-field v-model="form.player1_phone"
                              type="tel"
                              v-mask="player1PhoneMask"
                              :error-messages="validationMessageField($v.form.player1_phone, ['required', 'validPhone'], this.form.player1_phone, this.player1PhoneMask.length)"
                              @input="$v.form.player1_phone.$touch()"
                              @blur="$v.form.player1_phone.$touch()"
                              :prefix="player1phonePrefix"
                              :label="$t('app.domains.registration.components.form.form.phoneLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.phonePlaceholder')">
                  <template v-slot:label>
                  <span
                      :class="{'red--text': !form.player1_phone}">{{
                      $t('app.domains.registration.components.form.form.phoneLabel')
                    }}*</span>
                  </template>
                </v-text-field>
                <template v-if="registrationSexRequired">
                  <v-select v-model="form.player1_sex"
                            :items="dependencies.sex"
                            :error-messages="validationMessageField($v.form.player1_sex)"
                            @input="$v.form.player1_sex.$touch()"
                            @blur="$v.form.player1_sex.$touch()"
                            :label="$t('app.domains.registration.components.form.form.sexLabel')"
                            :placeholder="$t('app.domains.registration.components.form.form.sexLabel')"
                            item-text="name"
                            item-value="id">
                    <template v-slot:label>
                    <span
                        :class="{'red--text': !form.player1_sex}">{{
                        $t('app.domains.registration.components.form.form.sexLabel')
                      }}*</span>
                    </template>
                  </v-select>
                </template>
                <template v-if="registrationShirtRequired">
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-select v-model="form.player1_data.shirt_type"
                                :items="dependenciesShirtTypeFiltered"
                                :label="$t('app.domains.registration.components.form.form.shirtTypeLabel')"
                                :placeholder="$t('app.domains.registration.components.form.form.shirtTypeLabel')"
                                item-text="name"
                                item-value="id">
                        <template v-slot:label>
                        <span
                            :class="{'red--text': !form.player1_data.shirt_type}">{{
                            $t('app.domains.registration.components.form.form.shirtTypeLabel')
                          }}*</span>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs6>
                      <v-select v-model="form.player1_data.shirt_size"
                                :disabled="!form.player1_data.shirt_type"
                                :items="dependenciesShirtSizeFiltered(form.player1_data.shirt_type)"
                                :label="$t('app.domains.registration.components.form.form.shirtSizeLabel')"
                                :placeholder="$t('app.domains.registration.components.form.form.shirtSizeLabel')"
                                item-text="name"
                                item-value="id">
                        <template v-slot:label>
                        <span
                            :class="{'red--text': !form.player1_data.shirt_size}">{{
                            $t('app.domains.registration.components.form.form.shirtSizeLabel')
                          }}*</span>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                </template>
              </v-flex>
              <v-flex sm6 xs12 :class="{'mt-5': $vuetify.breakpoint.xs}" v-if="!showFormForSinglePlayer">
                <h4>{{ $t('app.domains.registration.components.form.form.playerTitle', {number: 2}) }}</h4>
                <v-select v-model="form.player2_country_code"
                          :label="$t('app.domains.registration.components.form.form.countryLabel')"
                          :placeholder="$t('app.domains.registration.components.form.form.countryLabel')"
                          :items="dependencies.countries"
                          item-value="code"
                          item-text="name"
                          :error-messages="validationMessageField($v.form.player2_country_code)"
                          @input="$v.form.player2_country_code.$touch()"
                          @blur="$v.form.player2_country_code.$touch()"
                          @change="player2ChangeCountry">
                  <template slot="item" slot-scope="data">
                    <v-list-item-avatar>
                      <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-avatar>
                      <img :src="`/static/flags/${item.flag_icon}`" alt="">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <template v-if="registrationCityRequired">
                  <v-autocomplete v-model="form.player2_city_id"
                                  :search-input.sync="player2SearchCity"
                                  :items="dependencies.player2_cities"
                                  :error-messages="validationMessageField($v.form.player2_city_id)"
                                  :no-data-text="player2SearchCity && player2SearchCity.length > 0 ? $t('app.domains.registration.components.form.form.citiesEmptyList') : $t('app.domains.registration.components.form.form.cityTypeLabel')"
                                  item-value="id"
                                  item-text="name"
                                  clearable
                                  no-filter
                                  :label="$t('app.domains.registration.components.form.form.cityLabel')"
                                  :placeholder="$t('app.domains.registration.components.form.form.cityLabel')">
                    <template v-slot:label>
                      <span :class="{'red--text': !form.player2_city_id}">
                        {{ $t('app.domains.registration.components.form.form.cityLabel') }}*
                      </span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }}
                          <span v-if="data.item.state"> - {{ data.item.state.initials }}</span>
                          <span v-if="data.item.country"> / {{ data.item.country.code }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }}
                          <span v-if="item.state"> - {{ item.state.initials }}</span>
                          <span v-if="item.country"> / {{ item.country.code }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </template>
                <template v-if="registrationCpfRequired">
                  <v-text-field v-model="form.player2_cpf"
                                type="tel"
                                @change="onUpdatePlayerData"
                                :error-messages="validationMessageField($v.form.player2_cpf)"
                                @input="$v.form.player2_cpf.$touch()"
                                @blur="$v.form.player2_cpf.$touch()"
                                :placeholder="player2UniqueIdentityLabel">
                    <template v-slot:label>
                      <span :class="{'red--text': !form.player2_cpf}">{{ player2UniqueIdentityLabel }}*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-text-field v-model="form.player2_first_name"
                              :error-messages="validationMessageField($v.form.player2_first_name)"
                              @input="$v.form.player2_first_name.$touch()"
                              @blur="$v.form.player2_first_name.$touch()"
                              :label="$t('app.domains.registration.components.form.form.firstNameLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.firstNamePlaceholder')">
                  <template v-slot:label>
                  <span
                      :class="{'red--text': !form.player2_first_name}">{{
                      $t('app.domains.registration.components.form.form.firstNameLabel')
                    }}*</span>
                  </template>
                </v-text-field>
                <v-text-field v-model="form.player2_last_name"
                              :error-messages="validationMessageField($v.form.player2_last_name)"
                              @input="$v.form.player2_last_name.$touch()"
                              @blur="$v.form.player2_last_name.$touch()"
                              :label="$t('app.domains.registration.components.form.form.lastNameLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.lastNamePlaceholder')">
                  <template v-slot:label>
                  <span
                      :class="{'red--text': !form.player2_last_name}">{{
                      $t('app.domains.registration.components.form.form.lastNameLabel')
                    }}*</span>
                  </template>
                </v-text-field>
                <v-text-field v-model="form.player2_email"
                              :error-messages="validationMessageField($v.form.player2_email, ['required', 'validEmail'])"
                              @change="onUpdatePlayerData"
                              @input="$v.form.player2_email.$touch()"
                              @blur="$v.form.player2_email.$touch()"
                              :label="$t('app.domains.registration.components.form.form.emailLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.emailPlaceholder')">
                  <template v-slot:label>
                  <span
                      :class="{'red--text': !form.player2_email}">{{
                      $t('app.domains.registration.components.form.form.emailLabel')
                    }}*</span>
                  </template>
                </v-text-field>
                <v-text-field v-model="form.player2_phone"
                              type="tel"
                              @change="onUpdatePlayerData"
                              v-mask="player2PhoneMask"
                              :error-messages="validationMessageField($v.form.player2_phone, ['required', 'validPhone'], this.form.player2_phone, this.player2PhoneMask.length)"
                              @input="$v.form.player2_phone.$touch()"
                              @blur="$v.form.player2_phone.$touch()"
                              :prefix="player2phonePrefix"
                              :label="$t('app.domains.registration.components.form.form.phoneLabel')"
                              :placeholder="$t('app.domains.registration.components.form.form.phonePlaceholder')">
                  <template v-slot:label>
                  <span
                      :class="{'red--text': !form.player2_phone}">{{
                      $t('app.domains.registration.components.form.form.phoneLabel')
                    }}*</span>
                  </template>
                </v-text-field>
                <template v-if="registrationSexRequired">
                  <v-select v-model="form.player2_sex"
                            :items="dependencies.sex"
                            :error-messages="validationMessageField($v.form.player2_sex)"
                            @input="$v.form.player2_sex.$touch()"
                            @blur="$v.form.player2_sex.$touch()"
                            :label="$t('app.domains.registration.components.form.form.sexLabel')"
                            :placeholder="$t('app.domains.registration.components.form.form.sexLabel')"
                            item-text="name"
                            item-value="id">
                    <template v-slot:label>
                    <span
                        :class="{'red--text': !form.player2_sex}">{{
                        $t('app.domains.registration.components.form.form.sexLabel')
                      }}*</span>
                    </template>
                  </v-select>
                </template>
                <template v-if="registrationShirtRequired">
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-select v-model="form.player2_data.shirt_type"
                                :items="dependenciesShirtTypeFiltered"
                                :error-messages="validationMessageField($v.form.player2_data.shirt_type)"
                                @input="$v.form.player2_data.shirt_type.$touch()"
                                @blur="$v.form.player2_data.shirt_type.$touch()"
                                :label="$t('app.domains.registration.components.form.form.shirtTypeLabel')"
                                :placeholder="$t('app.domains.registration.components.form.form.shirtTypeLabel')"
                                item-text="name"
                                item-value="id">
                        <template v-slot:label>
                        <span :class="{'red--text': !form.player2_data.shirt_type}">
                          {{ $t('app.domains.registration.components.form.form.shirtTypeLabel') }}*
                        </span>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex xs6>
                      <v-select v-model="form.player2_data.shirt_size"
                                :disabled="!form.player2_data.shirt_type"
                                :items="dependenciesShirtSizeFiltered(form.player2_data.shirt_type)"
                                :error-messages="validationMessageField($v.form.player2_data.shirt_size)"
                                @input="$v.form.player2_data.shirt_size.$touch()"
                                @blur="$v.form.player2_data.shirt_size.$touch()"
                                :label="$t('app.domains.registration.components.form.form.shirtSizeLabel')"
                                :placeholder="$t('app.domains.registration.components.form.form.shirtSizeLabel')"
                                item-text="name"
                                item-value="id">
                        <template v-slot:label>
                        <span
                            :class="{'red--text': !form.player2_data.shirt_size}">{{
                            $t('app.domains.registration.components.form.form.shirtSizeLabel')
                          }}*</span>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                </template>
              </v-flex>
              <v-flex xs12 v-if="stageLoaded && stage.impediments.length > 0">
                <h4>
                  {{ $t('app.domains.registration.components.form.impediments.title') }}
                </h4>
                <div v-for="item in stage.impediments" :key="item.id">
                  <v-checkbox v-model="form.impediments"
                              :value="item.impediment_id"
                              :label="item.name"
                              color="primary"
                              @change="checkImpediment($event, item.impediment_id)"
                              hide-details/>
                </div>
              </v-flex>
              <v-flex sm6 xs12 v-if="stageLoaded && stage.settings?.impediments_message">
                <v-alert color="yellow lighten-3"
                         :value="true"
                         icon="fa-exclamation-triangle"
                         class="rounded">
                  <div class="black--text" style="white-space: pre-wrap;" v-html="stage.settings.impediments_message"/>
                </v-alert>
              </v-flex>
              <v-flex sm6 xs12
                      v-if="stageLoaded && (stage.settings?.contact_email || stage.settings?.contact_phones)"
                      class="mt-1 mb-2">
                <div class="stage-contact-container rounded">
                  <h4 class="fs-18 primary--text">
                    {{ $t('app.domains.registration.components.form.form.contactTitle') }}:
                  </h4>
                  <div class="secondary--text">
                    <div v-if="stage.settings?.contact_email" key="contactEmail" class="stage-contact-item">
                      <i class="fas fa-envelope"></i> <span
                        class="pl-2">{{ stage.settings.contact_email }}</span>
                    </div>
                    <div v-if="stage.settings?.contact_phones" key="contactPhones" class="stage-contact-item">
                      <i class="fas fa-phone"></i> <span
                        class="pl-2">{{ stage.settings.contact_phones }}</span>
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12 v-if="isProduction" class="mt-2">
                <vue-recaptcha :sitekey="recaptchaKey"
                               @verify="verifyRecaptcha"
                               @expired="expiredRecaptcha"></vue-recaptcha>
              </v-flex>
            </template>
          </v-layout>
          <template v-if="stageLoaded">
            <v-alert :value="true"
                     v-if="stage.settings?.accept_terms !== null && stage.settings.accept_terms?.enabled"
                     class="mb-0 pt-0 pb-0 rounded">
              <div>
                <v-checkbox v-model="form.accept_terms">
                  <template v-slot:label>
                    <span v-if="stage.settings?.accept_terms?.title">{{ stage.settings.accept_terms.title }}</span>
                    <span v-else>
                      Eu li e concordo com os termos e condições de uso do Gripo
                  </span>
                  </template>
                </v-checkbox>
                <div v-if="stage.settings?.accept_terms?.text">{{ stage.settings.accept_terms.text }}</div>
              </div>
            </v-alert>
            <v-btn color="success"
                   block
                   large
                   @click="confirmRegistration()"
                   :disabled="isSubmitted || $v.form.$invalid || (isProduction && !isRecaptchaValid) || acceptTermsRequiredAndInvalid">
              <i class="fas fa-check"></i>
              <span class="pl-2">{{ $t('app.domains.registration.components.form.form.registrationConfirmationBtn') }}</span>
            </v-btn>
            <v-flex class="mt-4" xs12 v-if="stageMasterSponsors.length > 0">
              <div class="subheading font-weight-bold text-center mb-4">
                {{ $t('app.domains.registration.components.form.sponsors.masterText') }}:
              </div>
              <div class="sponsors-list">
                <div v-for="(image, imageIndex) in stageMasterSponsors"
                     :key="imageIndex"
                     :class="['logo-box', {'-white-logo': image.json && image.json.white_logo}]">
                  <a class="logo"
                     v-if="image.json && image.json.link"
                     target="_blank"
                     :href="image.json.link"
                     :title="image.json && image.json.name ? image.json.name : ''"
                     :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></a>
                  <div class="logo"
                       v-else
                       :title="image.json && image.json.name ? image.json.name : ''"
                       :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></div>
                </div>
              </div>
            </v-flex>
            <v-flex class="mt-4" xs12 v-if="stageSponsors.length > 0">
              <div class="subheading font-weight-bold text-center mb-4">
                {{ $t('app.domains.registration.components.form.sponsors.othersText') }}:
              </div>
              <div class="sponsors-list -small">
                <div v-for="(image, imageIndex) in stageSponsors"
                     :key="imageIndex"
                     :class="['logo-box', {'-white-logo': image.json && image.json.white_logo}]">
                  <a class="logo"
                     v-if="image.json && image.json.link"
                     target="_blank"
                     :href="image.json.link"
                     :title="image.json && image.json.name ? image.json.name : ''"
                     :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></a>
                  <div class="logo"
                       v-else
                       :title="image.json && image.json.name ? image.json.name : ''"
                       :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></div>
                </div>
              </div>
            </v-flex>
          </template>
        </template>
        <template v-if="isRegistrationFinished">
          <EmptyList :title="payment.expired
            ? $t('app.domains.registration.components.form.form.registrationFinished.titlePaymentExpired')
            : $t('app.domains.registration.components.form.form.registrationFinished.titleSuccess')"
                     message="">
            <div class="text-center mt-5">
              <div v-if="payment.expired">
                <v-btn color="warning"
                       large
                       @click="isRegistrationFinished = false">
                  <i class="fas fa-sync-alt"></i>
                  <span class="pl-2">
                    {{ $t('app.domains.registration.components.form.form.registrationFinished.btnTryAgain') }}
                  </span>
                </v-btn>
              </div>
              <div class="mt-2 mb-2" v-if="paymentEnabled && payment.transaction?.payment_link">
                <v-btn color="info"
                       large
                       :href="payment.transaction?.payment_link"
                       target="_blank">
                  <i class="fas fa-arrow-right"></i>
                  <span class="pl-2">
                    {{ $t('app.domains.registration.components.form.form.registrationFinished.btnGoToPayment') }}
                  </span>
                </v-btn>
              </div>
              <v-btn color="success"
                     large
                     v-if="!payment.expired"
                     :to="{name: 'registration.index', params: {transaction: null}}">
                <i class="fas fa-list-alt"></i>
                <span class="pl-2">
                  {{ $t('app.domains.registration.components.form.form.registrationFinished.btnShowMyRegistrations') }}
                </span>
              </v-btn>
            </div>
            <p v-if="stage.settings?.contact_phones"
               class="mt-5"
               v-html="$t('app.domains.registration.components.form.form.registrationFinished.contact', {contact: stage.settings.contact_phones})"/>
            <transaction-payment-modal :transaction="payment.transaction" :show.sync="payment.showModal"/>
          </EmptyList>
        </template>
      </v-container>
    </v-card>
    <v-dialog v-if="showConfirmDialog"
              v-model="showConfirmDialog"
              fullscreen hide-overlay
              transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="secondary">
          <v-toolbar-title>{{
              $t('app.domains.registration.components.form.confirmationDialog.title')
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="handleShowConfirmDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-list subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="grey--text darken-1">
                    {{ $t('app.domains.registration.components.form.form.stageLabel') }}
                  </div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="subheading font-weight-medium">{{ stage.name_with_circuit }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="grey--text darken-1">
                    {{ $t('app.domains.registration.components.form.form.playerClassSelectedLabel') }}
                  </div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="subheading font-weight-medium">{{ selectedPlayerClass.name_with_sex }}</span>
              </v-list-item-action>
            </v-list-item>
            <template v-if="!!selectedImpediments">
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title style="white-space: pre-line; ">
                    <div class="grey--text darken-1">
                      {{ $t('app.domains.registration.components.form.impediments.title') }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="subheading font-weight-medium">{{ selectedImpediments }}</span>
                </v-list-item-action>
              </v-list-item>
            </template>
            <div v-if="paymentEnabled" class="mb-5">
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="grey--text darken-1">
                      {{ $t('app.domains.registration.components.form.form.amountTotalLabel') }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="subheading font-weight-medium">
                    {{ (form.player1_full_amount + form.player2_full_amount) | currencyEnToBr }}
                  </div>
                  <div class="subheading font-weight-bold fs-14 text-danger"
                       v-if="(form.player1_discount + form.player2_discount) > 0">
                    - {{ (form.player1_discount + form.player2_discount) | currencyEnToBr }}
                    ( {{ t('app.domains.registration.components.form.form.amountDiscountLabel') }} )
                  </div>
                  <div class="subheading font-weight-bold fs-14"
                       v-if="!!form.coupon">
                    CUPOM: {{form.coupon}}
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="grey--text darken-1 fs-18">
                      {{ $t('app.domains.registration.components.form.form.amountFinalLabel') }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="subheading font-weight-medium fs-18 text-success">
                    {{ (form.player1_amount + form.player2_amount) | currencyEnToBr }}
                  </div>
                </v-list-item-action>
              </v-list-item>
            </div>
            <template v-if="hasCouponEnabled">
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="grey--text darken-1">
                      {{ $t('app.domains.registration.components.form.form.couponHeaderLabel') }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field v-model="couponModel"
                                single-line
                                outlined
                                dense
                                hide-details
                                :placeholder="$t('app.domains.registration.components.form.form.couponLabel')">
                  </v-text-field>
                  <v-btn :dark="!!couponModel" class="mt-1" @click="onApplyCoupon" :disabled="!couponModel">
                    {{ $t('app.domains.registration.components.form.form.couponApplyLabel') }}
                  </v-btn>
                  <div class="text-danger mt-1" v-if="!!form.coupon_error">
                    {{ form.coupon_error }}
                  </div>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-layout wrap class="mt-5">
              <v-flex :md="showFormForSinglePlayer ? 12 : 5" :xs="12">
                <v-list subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1 font-weight-bold">
                          <template v-if="showFormForSinglePlayer">
                            {{ $t('app.domains.registration.components.form.form.playerTitleSingle') }}
                          </template>
                          <template v-else>
                            {{ $t('app.domains.registration.components.form.form.playerTitle', {number: 1}) }}
                          </template>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.countryLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ playerSelectedCountry(1) }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.firstNameLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ form.player1_first_name }} {{ form.player1_last_name }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.emailLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ form.player1_email }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.phoneLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ form.player1_phone }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <template v-if="registrationCpfRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ player1UniqueIdentityLabel }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ form.player1_cpf }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="registrationSexRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.sexLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ playerSelectedSex(1) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="registrationCityRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.cityLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ playerSelectedCity(1) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="registrationShirtRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.shirtLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ playerSelectedShirt(1) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="paymentEnabled && !form.settings.payment_unique && !showFormForSinglePlayer">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.amountLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ form.player1_full_amount | currencyEnToBr }}
                        </div>
                        <div class="subheading font-weight-bold fs-14 text-danger" v-if="form.player1_discount > 0">
                          - {{ form.player1_discount | currencyEnToBr }}
                          ({{ t('app.domains.registration.components.form.form.amountDiscountLabel') }})
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
              <v-flex v-if="!$vuetify.breakpoint.xs && !showFormForSinglePlayer"></v-flex>
              <v-flex :md="5" :xs="12" v-if="!showFormForSinglePlayer">
                <v-list subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1 font-weight-bold">
                          {{ $t('app.domains.registration.components.form.form.playerTitle', {number: 2}) }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.countryLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ playerSelectedCountry(2) }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.firstNameLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ form.player2_first_name }} {{ form.player2_last_name }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.emailLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ form.player2_email }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="grey--text darken-1">
                          {{ $t('app.domains.registration.components.form.form.phoneLabel') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="subheading font-weight-medium">
                        {{ form.player2_phone }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                  <template v-if="registrationCpfRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ player2UniqueIdentityLabel }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ form.player2_cpf }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="registrationSexRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.sexLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ playerSelectedSex(2) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="registrationCityRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.cityLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ playerSelectedCity(2) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="registrationShirtRequired">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.shirtLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ playerSelectedShirt(2) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="paymentEnabled && !form.settings.payment_unique">
                    <v-divider/>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="grey--text darken-1">
                            {{ $t('app.domains.registration.components.form.form.amountLabel') }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="subheading font-weight-medium">
                          {{ form.player2_full_amount | currencyEnToBr }}
                        </div>
                        <div class="subheading font-weight-bold fs-14 text-danger" v-if="form.player2_discount > 0">
                          - {{ form.player2_discount | currencyEnToBr }}
                          ({{ t('app.domains.registration.components.form.form.amountDiscountLabel') }})
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
            </v-layout>
            <template v-if="stage.settings?.impediments_message">
              <v-divider class="mt-5"/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title style="white-space: pre-line; ">
                    <div class="grey--text darken-1" v-html="stage.settings.impediments_message"/>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <div class="text-center mt-3">
            <v-btn color="primary"
                   large
                   @click="saveRegistration()"
                   :disabled="isSubmitted || $v.form.$invalid || (isProduction && !isRecaptchaValid) || acceptTermsRequiredAndInvalid">
              <i class="fas fa-check"></i>
              <span class="ml-2">
                {{ $t('app.domains.registration.components.form.form.registrationBtn') }}
              </span>
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
